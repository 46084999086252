import LazyLoad from "vanilla-lazyload";
function ready(callbackFunc) {
  if (document.readyState !== 'loading') {
    // Document is already ready, call the callback directly
    callbackFunc();
  } else if (document.addEventListener) {
    // All modern browsers to register DOMContentLoaded
    document.addEventListener('DOMContentLoaded', callbackFunc);
  } else {
    // Old IE browsers
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callbackFunc();
      }
    });
  }
}
ready(function () {
  const DOM_ITEM_HTML = document.querySelector("HTML");
  const OVERLAY_OPEN_CLASS = "overflow-hidden";

  window.FullscreenTakeover = {
    classes: {
      UTILITY: {
        OPENING: "opacity-0 visibility-hidden pointer-events-none",
        SHOW_IMAGE: "opacity-100 relative",
        HIDE_IMAGE: "opacity-0 absolute"
      },
      DOM: {
        OVERLAY: "js-fullscreen-takeover",
        CLOSE: "js-fullscreen-takeover--close",
        NEXT: "js-fullscreen-takeover--next",
        PREV: "js-fullscreen-takeover--prev",
        CAPTION: "js-fullscreen-takeover--caption",
        INDEX: "js-fullscreen-takeover--index",
        IMAGE_WRAPPER: "js-fullscreen-takeover--img-wrapper",
        IMG: "js-img-wrapper"
      }
    },
    scope: undefined,
    frameOpening() {
      return (`<section class="${this.classes.DOM.OVERLAY} ${this.classes.UTILITY.OPENING} content-block fixed overflow-hidden left-0 top-0 right-0 bottom-0 z-50 md:p-10 ${this.classes.opening} transition-opacity duration-300">
                                <div class="${this.classes.DOM.CLOSE} absolute left-0 top-0 right-0 bottom-0 z-0 bg-velux-grey-900 bg-opacity-95"></div>
                                    <div class="relative h-full md:h-auto max-w-full md:max-w-1280 md:tranform md:transform md:top-1/2 md:-translate-y-1/2 md:left-1/2 md:-translate-x-1/2 max-h-screen">
                                        <button class="${this.classes.DOM.CLOSE} bg-velux-grey-900 w-10 h-10 bg-opacity-50 fixed md:hidden right-3 top-3 text-velux-white flex items-center justify-center z-10">
                                            <i class="fas fa-times fa-lg"></i>
                                        </button>`);
    },
    frameClosing() {
      return `</div></section>`;
    },
    data: {
      activeIndex: 0,
      imageArraySize: 0
    },
    init() {
      this.createclassLists(); // create class arrays for classlist add/remove usage with spread (...) operator
      this.scope = document.querySelector("." + this.classes.DOM.OVERLAY);
      this.setUpCloseEventListeners();
      this.setUpGalleryEventListeners();
      this.setActiveCaption();
      this.setLazyLoad();

      setTimeout(() => {
        this.scope.classList.remove(...this.classLists.OPENING); // Show overlay with css transition animation by removing initial classes that hid it
        DOM_ITEM_HTML.classList.add(OVERLAY_OPEN_CLASS); // hide scrollbar
      }, 100);
    },
    createclassLists() {
      this.classLists = {};

      const keys = Object.keys(this.classes.UTILITY);

      for (const key of keys) {
        this.classLists[key] = this.classes.UTILITY[key].split(" ");
      }
    },
    openVideo(videoSrc, isVideo23) {
      // Called from Video or Video Plus Copy content block - video-cookie-popup.js
      let videoFragment = "";
      videoFragment += this.frameOpening();

      videoFragment += `
            <div class="relative h-full grid items-center md:block">
                <button class="${this.classes.DOM.CLOSE} bg-velux-grey-900 w-10 h-10 bg-opacity-80 hidden absolute md:flex right-3 top-3 text-velux-white items-center justify-center z-10">
                    <i class="fas fa-times fa-lg"></i>
                </button>
                <div class="aspect-w-16 aspect-h-9 relative">
                    <iframe
                        width="100%"
                        height="auto"
                        src="${videoSrc + isVideo23}"
                        frameborder="0"
                        allow="autoplay"
                        allowfullscreen="allowfullscreen"
                    ></iframe>
                </div>
            </div>`;

      videoFragment += this.frameClosing();
      this.drawOverlay(videoFragment);
    },
    openGallery(images = [], activeImageIndex = 0, captions = []) {
      // Called from Gallery content block - gallery.js
      this.data.activeIndex = activeImageIndex;
      this.data.imageArraySize = images.length;
      this.data.captions = captions;
      let arrImg = [];

      // build the right fragment for the gallery based on images[] parameter
      if (images.length) {
        images.forEach((img, index) => {
          let wrapper = `<div class="${this.classes.DOM.IMAGE_WRAPPER} transition-opacity duration-100 `;

          if (index === activeImageIndex) {
            wrapper += this.classes.UTILITY.SHOW_IMAGE;
          } else {
            wrapper += this.classes.UTILITY.HIDE_IMAGE;
          }
          wrapper += `"> ${this.nodeToString(img)} </div>`;

          arrImg.push(wrapper);
        });
      }

      // build gallery fragment
      let galleryFragment = "";

      galleryFragment += this.frameOpening();
      galleryFragment += `
                    <div class="relative h-full grid items-center md:block">
                        <div>
                          <button class="${this.classes.DOM.CLOSE} bg-velux-grey-900 w-10 h-10 bg-opacity-50 fixed md:absolute md:flex right-3 top-3 text-velux-white items-center justify-center z-10">
                            <i class="fas fa-times fa-lg"></i>
                          </button>`;

      arrImg.forEach(img => {
        galleryFragment += img;
      });

      galleryFragment += `
                    </div>
                  </div>`;
      galleryFragment += `
            <div class="bg-velux-grey-900 bg-opacity-80 absolute left-0 right-0 bottom-0 text-velux-white text-center px-2 md:px-4 pt-2 pb-3 sm:pb-2 md:flex md:justify-between md:items-center md:pb-2">
                <p class="${this.classes.DOM.CAPTION} pb-1 md:pb-0 text-xl"></p>`;

      if (images.length > 1) {
        galleryFragment += `
                <div class="inline-flex items-center ml-auto mt-0">
                    <button class="${this.classes.DOM.PREV
          } inline-flex items-center flex-auto justify-center w-6 h-6 rotate-90 transform text-xl"> 
                        <i class="far fa-chevron-down"></i>
                    </button>
                    <span class="w-12 inline-block whitespace-nowrap text-xl">
                       <span class="${this.classes.DOM.INDEX}">
                       ${this.data.activeIndex + 1}</span> / ${this.data.imageArraySize}
                    </span>
                    <button class="${this.classes.DOM.NEXT
          } inline-flex items-center flex-auto justify-center w-6 h-6 -rotate-90 transform text-xl">
                        <i class="far fa-chevron-down"></i>
                    </button>
                </div>`;
      }

      galleryFragment += `</div>`;
      galleryFragment += this.frameClosing();

      this.drawOverlay(galleryFragment);
    },
    nodeToString(node) {
      var tmpNode = document.createElement("div");
      tmpNode.appendChild(node.cloneNode(true));
      var str = tmpNode.innerHTML;

      return str;
    },
    drawOverlay(fragment) {
      document.body.insertAdjacentHTML("beforeend", fragment);

      this.init(); // Fragment is ready, we can initiate the overlay
    },
    setUpCloseEventListeners() {
      Array.from(
        this.scope.querySelectorAll("." + this.classes.DOM.CLOSE)
      ).forEach(close => {
        close.addEventListener("click", event => {
          event.preventDefault();
          this.close();
        });
      });
    },
    setUpGalleryEventListeners() {
      let prevArrow = this.scope.querySelector("." + this.classes.DOM.PREV);
      let nextArrow = this.scope.querySelector("." + this.classes.DOM.NEXT);

      if (prevArrow) {
        prevArrow.addEventListener("click", event => {
          event.preventDefault();
          this.previousSlide();
        });
      }

      if (nextArrow) {
        nextArrow.addEventListener("click", event => {
          event.preventDefault();
          this.nextSlide();
        });
      }
    },
    setActiveCaption() {
      let caption = this.scope.querySelector("." + this.classes.DOM.CAPTION);
      let captionText = this.data.captions
        ? this.data.captions[this.data.activeIndex]
        : "";

      if (caption) caption.textContent = captionText;
    },
    setActiveImage() {
      let activeImage = this.scope.getElementsByClassName(
        this.classes.UTILITY.SHOW_IMAGE
      )[0];
      if (activeImage) {
        activeImage.classList.remove(...this.classLists.SHOW_IMAGE);
        activeImage.classList.add(...this.classLists.HIDE_IMAGE);
      }

      let imageToShow = this.scope.querySelectorAll("." + this.classes.DOM.IMAGE_WRAPPER)[this.data.activeIndex];

      imageToShow.classList.add(...this.classLists.SHOW_IMAGE);
      // Opacity won't be handled by LazyLoad, so we remove display: none when we need to load the image
      this.lazyLoadHelper(imageToShow);
      imageToShow.classList.remove(...this.classLists.HIDE_IMAGE);

      this.scope.querySelector("." + this.classes.DOM.INDEX).textContent = this.data.activeIndex + 1;
      this.setActiveCaption();
    },
    setLazyLoad() {
      // Check if we have images at all, because it can be a video fullscreen takeover
      let images = this.scope.querySelectorAll('.' + this.classes.DOM.IMAGE_WRAPPER);
      if (images && images.length) {
        const lazyGalleryImages = {
          data_src: "original",
          elements_selector: ".js-fullscreen-takeover .lazy"
        };
        this.lazyLoadHelper();
        new LazyLoad(lazyGalleryImages);
      }
    },
    lazyLoadHelper(imgWrapper) {
      let imageToShow = imgWrapper ? imgWrapper : this.scope.querySelectorAll("." + this.classes.DOM.IMAGE_WRAPPER)[this.data.activeIndex];
      let images = imageToShow.querySelectorAll("." + this.classes.DOM.IMG);
      Array.from(images).forEach(img => img.style.removeProperty('display'));
    },
    close() {
      this.scope.classList.add(...this.classLists.OPENING);
      DOM_ITEM_HTML.classList.remove(OVERLAY_OPEN_CLASS);

      setTimeout(() => {
        this.scope.remove();
      }, 300);
    },
    previousSlide() {
      if (this.data.activeIndex !== 0) {
        this.data.activeIndex--;
      } else {
        this.data.activeIndex = this.data.imageArraySize - 1;
      }
      this.setActiveImage();
    },
    nextSlide() {
      if (this.data.activeIndex !== this.data.imageArraySize - 1) {
        this.data.activeIndex++;
      } else {
        this.data.activeIndex = 0;
      }
      this.setActiveImage();
    }
  };

});
